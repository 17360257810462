/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'icon';  /* Project id 4308718 */
  src: url('//at.alicdn.com/t/c/font_4308718_121z9rwyxtx.woff2?t=1703243792856') format('woff2');
}
.icon{
  font-family:"icon" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.icon.home::before {
  content: "\e95d";
}
.icon.sitemap::before {
  content: "\e600";
}
.icon.key::before {
  content: "\e60a";
}
.icon.dollar::before {
  content: "\e62d";
}
.icon.cart::before {
  content: "\e609";
  /* e88b */
}
.icon.user::before {
  content: "\e65e";
}
.icon.code::before {
  content: "\e95c";
}
.icon.setting::before {
  content: "\e998";
}
.icon.info::before {
  content: "\e959";
}
.icon.dropdown::before {
  content: "\e658";
  font-size: 12px;
}
.icon.sidebar::before {
  content: "\e60b";
  font-size: 20px;
}
.icon.close::before {
  content: "\e63d";
  font-size: 20px;
}
.icon.delete::before {
  content: "\e63d";
  font-size: 12px;
}
.icon.search::before {
  content: "\e630";
  font-size: 18px;
}
.icon.lock::before {
  content: "\e955";
}
.icon.github::before {
  content: "\e972";
  font-size: 24px;
}
/* 禁用 */
.icon.disable::before {
  content: "\e64a";
}
/* 启用 */
.icon.enable::before {
  content: "\e61f";
}
.icon.edit::before {
  content: "\e7ce";
}
.icon.test::before {
  content: "\e6be";
}
.icon.remove::before {
  content: "\e647";
}
.icon.copy::before {
  content: "\e613";
}
.icon.comments::before {
  content: "\e953";
}
.icon.mail::before {
  content: "\e618";
  font-size: 18px;
}
